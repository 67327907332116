<template>
  <div>
    <h3 ref="what">
      What is a Pictograph?
    </h3>
    <p class="mt-2">
      A pictograph (sometimes also called a pictogram) is the simplest way of representing data.
      In a pictograph, the data is represented using symbols and pictures.
      Each symbol or picture represents certain amount of a given item or object.
      Thus, by looking at the occurrence or repetition of a symbol,  one can determine the absolute amount of the object it represents.
      Since in pictographs, data is represented using pictures and images, pictographs are very easy to create, read and understand.
    </p>
    <br>
    <h3 ref="example">
      Example of a Pictograph
    </h3>
    <p class="mt-2">
      Imagine you are a dog seller (i.e. someone who breeds and sells dogs).
      Let's say you breed 5 different kinds of dogs: Bulldog, Doberman, Greyhound, Dalmatian and Shar-Pei.
      Let's assume that in a given month, you sold 20 Bulldogs, 30 Dobermans, 40 Greyhounds, 10 Dalmatian and 20 Shar-Peis.
      The following pictograph portrays this information in a visual form that is easy to read and understand.
    </p>
    <v-layout justify-center>
      <v-img src="/assets/PieChart.png"
             max-height="450px"
             max-width="450px"
             contain
      />
    </v-layout>
    <h3 ref="playgraph">
      MagicGraph &vert; Understanding a Pictograph
    </h3>
    <p class="mt-2">
      This MagicGraph offers a visually interactive tutorial that will help you understand how to read a pictograph.
    </p>
    <h5> To Get Started </h5>
    <p class="mt-2">
      John is a dog seller. Not all days of the week are same. Some days John sells more dogs, other days he sells only a few.
      The pictogram below shows the number of dogs sold by John on a day-by-day basis over the period of a week. <br>
      Now, let's say each dog is sold for $500. Simply tap on a day on the pictogram to display the value of dogs sold by John on that day of the week.
    </p>
    <h5> To Explore:</h5>
    <p class="mt-2">
      Tap on the shuffle button to generate the report for John from another week's sales activity.
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
    <!--
    <br>
    <h3 ref="playgraph2">
      MagicGraph &vert; Test Your Knowledge of Pictograms
    </h3>
    <p>
      This MagicGraph offers a visually interactive way to test your knowledge of bar charts. Drag each point to
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox2" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
 -->
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Angles',
  created: function () {
      this.$store.commit('navigation/resetState');
    // Store mutations
    let title = 'Pictograph';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'What is a Pie Chart?', img:'/assets/number-1.svg', action: () => this.goto('ca')},
      {title: 'Supplementary Angle', img:'/assets/number-2.svg', action: () => this.goto('sa')},
      {title: 'Special Remarks', img:'/assets/number-3.svg', action: () => this.goto('ra')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('playgraph')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
    Boxes.box2();
  },
  metaInfo() {
  return{ title: 'Pictogram',
          titleTemplate: '%s | Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively how to read a Pictogram.'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
#jxgbox2 input {border-style:solid;border-radius:4px;background-color:#008CBA}
</style>
